/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'variables';
@import 'mat-calendar';
@import 'mat-snackbar';


html,
body {
  height: 100%;
  background: white;
}

body {
  margin: 0;
  font-family: "Inter", "Arial", Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

.button-1 {
  @include mat.button-density(-1);
}

.button-2 {
  @include mat.button-density(-2);
}

.button-3 {
  @include mat.button-density(-3);
}

.form-field-1 {
  @include mat.form-field-density(-1);
}

.form-field-2 {
  @include mat.form-field-density(-2);
}

.form-field-3 {
  @include mat.form-field-density(-3);
}

.form-field-4 {
  @include mat.form-field-density(-4);
}

.form-field-5 {
  @include mat.form-field-density(-5);
}

a {
  color: #4b09b4
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
  border-bottom: none !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline;
}

.multi-line-text {
  white-space: normal;
  word-wrap: break-word;
  width: 100%;
}

.mail-composer-container .mat-dialog-container {
  padding: 0;
}

.hide-subscript .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.dense-input .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.dense-input .mdc-line-ripple {
  display: none;
}

.dense-input .mat-mdc-form-text-infix {
  padding: 0 !important;
  margin: 0 !important;
  border: none;
}

.dense-input .mat-mdc-form-field-text-prefix {
  margin-right: 10px !important;
}

.inquiry-dialog .mat-dialog-container,
.view-lead-dialog .mat-dialog-container {
  padding: 0;
}

.faq-dialog .mat-mdc-dialog-container {
  padding-top: 25px;
}

.note-editable p {
  margin-top: 0;
  margin-bottom: 0px;
}

.sdr-details+* .mat-menu-panel {
  background-color: black;
  color: white;
}

.mat-menu-panel.sdr-details {
  background-color: black;
  color: white;

  div {
    padding: 10px 5px 10px;
  }
}

.wd-modal {
  .mat-dialog-container {
    display: flex;
    padding: 0;
  }
}

.note-editor .note-editing-area .note-editable a {
  color: $primary-800 !important;
}